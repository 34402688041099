/*
 * mapLiveSubscriptionData
 * Takes in a graphQL response from the subscription queryMetricValue
 * and maps it to a collection of DataValue
 * 
*/
import { DateTime } from 'luxon';

// Types
import { DataValue } from "../../../@types/Data/DataValue";
import { DataMode } from "../../../@types/DataMode";
import { Metric, MetricValue } from "@repo/backend-types";

// Utils
import { mapMetric } from "./mapMetric";

export const mapLiveSubscriptionData = (metricValues: MetricValue[], metrics: Metric[]): { data: DataValue[], warning: boolean } => {

    const data: DataValue[] = []
    const nowInTimeZone = DateTime.utc()
    const millisSinceEpoch = nowInTimeZone.toMillis()

    let mostRecentDateTime: DateTime | null = null
    let mostRecentMillis = 0
    let warning = false

    metricValues.forEach(mv => {

        // Tracking the most recent timestamp on the data
        if (!mostRecentDateTime) {
            mostRecentDateTime = DateTime.fromISO(mv.timestamp, { zone: 'utc' })
        } else {
            let recordDate = DateTime.fromISO(mv.timestamp, { zone: 'utc' })
            if (recordDate > mostRecentDateTime) {
                mostRecentMillis = recordDate.toMillis()
            }
        }

        // Map raw data into dataValue array
        data.push({
            entityId: mv.entityID,
            metric: mapMetric(DataMode.LIVE, metrics, mv.metricID),
            timestamp: mv.timestamp,
            value: mv.value,
        })
    });

    // Trigger warning if the most recent timestamp within the data is greater than 15 minutes
    const alarmInMilliseconds = 15 * 60 * 1000;
    if (millisSinceEpoch - mostRecentMillis > alarmInMilliseconds) {
        warning = true
    }

    return {data: data, warning: warning}
}