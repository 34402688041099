/* 
* UserContext
* Used to store the user account on authenication
* 
*/
import React, { createContext, useCallback, useContext, useMemo, useState } from "react"

// Types
import { Account } from "@repo/backend-types"
import { UserPrefs } from "../../@types/Settings/UserPrefs"
import { Tokens } from "../../@types/Settings/Tokens"

type AuthCreds = {
    authenticated: boolean,
    email: string,
    organisation: string,
    aiUrl: string,
    environment: string,
    graphqlUrl: string,
    tokens: Tokens
}

interface UserContextValue {
    authCreds: AuthCreds | null
    user: Account | null
    userPrefs: UserPrefs| null
    twinId: string | null
    setAuthCreds: (authCreds: AuthCreds | null) => void
    setUser: (user: Account | null) => void
    setUserPrefs: (userPrefs: UserPrefs | null) => void
    setTwinId: (twinId: string | null) => void
}

const initialState: UserContextValue = {
    authCreds: null,
    user: null,
    userPrefs: null,
    twinId: null,
    setAuthCreds: () => {},
    setUser: () => { },
    setUserPrefs: () => { },
    setTwinId: () => { },
}

export const UserContext = createContext<UserContextValue>(initialState)

export const useUserContext = (): UserContextValue => {
    return useContext(UserContext);
};

interface ContextProviderProps {
    children: React.ReactNode;
}

export const UserContextProvider: React.FC<ContextProviderProps> = (props) => {

    const [userState, setUserState] = useState<UserContextValue>(initialState);

    const setAuthCreds = useCallback((authCreds: AuthCreds | null) => {
        setUserState((prevUserState) => ({ ...prevUserState, authCreds }));
    }, [])

    const setUser = useCallback((user: Account | null) => {
        setUserState((prevUserState) => ({ ...prevUserState, user }));
    }, [])

    const setUserPrefs = (userPrefs: UserPrefs | null) => {
        setUserState((prevUserState) => ({ ...prevUserState, userPrefs }));
    };

    const setTwinId = useCallback((twinId: string | null) => {
        setUserState((prevUserState) => ({ ...prevUserState, twinId }));
    }, [])

    // Memoise the context value to prevent unnecessary re-renders
    const contextValue = useMemo(() => {
        return {
        ...userState,
        setAuthCreds,
        setUser,
        setUserPrefs,
        setTwinId,
      }}, [userState, setUser, setTwinId, setAuthCreds]);

    return (
        <UserContext.Provider
            value={contextValue}
        >
            {props.children}
        </UserContext.Provider>
    )
}
