

/*
 * mapTimeSeriesData
 * Takes in a time series response and maps it to a collection of DataValue
 * 
*/
import { DataMode } from "../../../@types/DataMode";
import { DataValue } from "../../../@types/Data/DataValue";
import { Metric } from "@repo/backend-types";
import { TSMetricData } from "../../../@types/Data/TSMetricData";

// Utils
import { convertUnixTimestampToDate } from "../convertUnixTimestampToDate";
import { mapMetric } from "./mapMetric";


export const mapTimeSeriesData = (timeSeriesmetricData: TSMetricData, metrics: Metric[]): DataValue[] => {
    
    const data: DataValue[] = []

    // Access each metric bucket in the TSMetricData object (e.g. headcount)
    const keys = Object.keys(timeSeriesmetricData)

    try {
        keys.forEach(key => {

            const TSMetricValues = timeSeriesmetricData[key]

            // Loop through the data contained within each 'bucket'
            TSMetricValues.forEach(mr => {
    
                // Set business ID (bID) (Known on TS response as "digitalTwinEntity")
                let bID = mr.metric.digitalTwinEntity
    
                // Set business ID path (Known on TS response as "digitalTwinEntityPath")
                let bIDPath = mr.metric.digitalTwinEntityPath
    
                // Set each "metric" (i.e. countEntity) and set as DataValue.metric
                let metric = mapMetric(DataMode.TIME_SERIES, metrics, mr.metric.metric)
    
                // For EACH Metric - Loop through each of the Values
                if (mr.values) {
                    mr.values.forEach(val => {
                        let dataValue: DataValue = {
                            bID: bID,
                            bIDPath: bIDPath,
                            metric: metric,
                            // Set timestamp by converting the Value Unix timestamp
                            timestamp: convertUnixTimestampToDate(val[0]).toISOString(),
                            // Set value
                            value: parseFloat(val[1]),
                        }
                        data.push(dataValue)
                    })
                }

                // Handling a single TSValue being returned
                if (mr.value) {
                    let dataValue: DataValue = {
                        bID: bID,
                        bIDPath: bIDPath,
                        metric: metric,
                        // Set timestamp by converting the Value Unix timestamp
                        timestamp: convertUnixTimestampToDate(mr.value[0]).toISOString(),
                        // Set value
                        value: parseFloat(mr.value[1]),
                    }
                    data.push(dataValue)
                }
            })
    
        })
    } catch (error) {
        console.error(`Error encountered in mapTimeSeriesData function`, error)
    }

    return data
}