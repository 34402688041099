import { TwinEntity } from "@repo/backend-types";
import { ColorRepresentation, Group, Material, Mesh, MeshLambertMaterial, MeshStandardMaterial, Object3D, Vector3 } from "three";
import { Station } from "./Station";
import { useGLTF } from "@react-three/drei";
import { useSecureModelURL } from "../../utils/modelFilePaths";
import { useEffect, useMemo, useState } from "react";
import { isMesh } from "../../helpers/threeTypeGuards";
import { ASSET_COLOR } from "../../theme";

interface Props {
	entity: TwinEntity,
	lineage: TwinEntity[],
	mapDiv: HTMLDivElement;
	depth: number;
	loadingList:Map<string, boolean>;
}

export const STATION_DEPTH = 3;
const OPERATOR_WIDTH = 1;
export const GAP_RATIO = 0.2;

const eventSiteMaterial = new MeshStandardMaterial({
	color: ASSET_COLOR,
	opacity:0.9,
	roughness: 0.8,
	metalness: 0.2,
})

function makeGateMaterial(color: ColorRepresentation, visible: boolean) {
	return new MeshLambertMaterial({ color, visible })
}

function disposeMaterial(material: Material) {
	material.dispose()

	// dispose textures
	for (const [, value] of Object.entries(material)) {
		if (value && typeof value === 'object' && 'minFilter' in value) {
			value.dispose()
		}
	}
}

function disposeMesh(mesh: Mesh) {

	const material = mesh.material
	const geometry = mesh.geometry

	geometry.dispose()

	if (Array.isArray(material)) {
		material.forEach(individualMaterial => disposeMaterial(individualMaterial))
	} else {
		disposeMaterial(material)
	}


 }

 function disposeScene(scene: Group) {
	scene.traverse((object) => {
		if (isMesh(object)) {
			disposeMesh(object)
		}
	})
 }

const COLOR_1 = 'green'
const COLOR_2 = 'red'
const COLOR_3 = 'blue'

const GROUP_LOOKUP = new Map([
	// 1
	['CK - Mesas VIP Stage',0],
	['CK - Staff ', 0],
	// 2
	['CK - GA', 1],
	// 3
	['CK - F&F Stage Experience (Guest)', 2],
	['CK - Stage Experience (Paid)', 2],
    ['CK - Mesas VIP Plataforma', 2], 
	['CK - THD', 2]
])

export const EventSpace = ({ entity, lineage, mapDiv, loadingList }: Props) => {
	
	const modelFile = 'howler-site.glb' 

	// TODO update loading list appropriately
	// don't use cache bust since it seems to be break useGLTF caching (obviously) and thereby lead to memory leak
	// however, not using cache bust is problematic since azure storage will throw errors (which is why it was introduced)
	const eventSiteModelURL = useSecureModelURL(modelFile, false) 
     
	const [toggle, rerender] = useState(false)
	
	const alreadyLoaded = loadingList.get(modelFile)
	if (!alreadyLoaded) {
		loadingList.set(modelFile, false)
	}

	const { nodes, scene } = useGLTF(eventSiteModelURL)

	useEffect(() => {

		Object.entries(nodes).forEach(entry => {
			const object = entry[1]
			if (isMesh(object)) {
				
				// dispose of old material
				const material = object.material
				if (Array.isArray(material)) {
					material.forEach(individualMaterial => disposeMaterial(individualMaterial))
				} else {
					disposeMaterial(material)
				}


				object.geometry.computeBoundingBox()
				if (object.parent?.name === 'entrance1') {
					object.material = makeGateMaterial(COLOR_1, false)
				} else if (object.parent?.name === 'entrance2') {
					object.material = makeGateMaterial(COLOR_2, false)
				} else if (object.parent?.name === 'entrance3') {
					object.material = makeGateMaterial(COLOR_3, false)
				} else if (object.parent?.name === 'entrance4') {
					object.material = makeGateMaterial(COLOR_3, false)
				} else {
					object.material = eventSiteMaterial
				}
			}})


		if (!loadingList.get(modelFile)) rerender(!toggle) // we have to trigger a re-render before the model is seen
		loadingList.set(modelFile, true)

	}, [nodes, loadingList, toggle])
	
    const stationContainer1 = useMemo(() => {
		const entrance = nodes['entrance1'].children[0]

		const position = new Vector3()
		
		entrance.getWorldPosition(position)
		const direction = new Vector3()
		entrance.getWorldDirection(direction)
		
		direction.y += Math.PI / 1.87 // pure trial and error
		
		const object = new Object3D()
		object.position.copy(position)
		object.rotation.setFromVector3(direction)

		object.translateX(- OPERATOR_WIDTH * 7) // pure trial and error
		object.translateZ(73)
		object.translateY(OPERATOR_WIDTH / 2)
		

		return object
	}, [nodes])

	const stationContainer2 = useMemo(() => {
		const entrance = nodes['entrance2'].children[0]

		const position = new Vector3()
		
		entrance.getWorldPosition(position)
		const direction = new Vector3()
		entrance.getWorldDirection(direction)
		
		direction.y -= 0.68 * Math.PI // pure trial and error
		
		const object = new Object3D()
		object.position.copy(position)
		object.rotation.setFromVector3(direction)

		object.translateX(- OPERATOR_WIDTH * 15) // pure trial and error
		object.translateZ(61) // pure trial and error
		object.translateY(OPERATOR_WIDTH / 2)
		

		return object
	}, [nodes])

	const stationContainer3 = useMemo(() => {
		const entrance = nodes['entrance3'].children[0]

		const position = new Vector3()
		
		entrance.getWorldPosition(position)
		const direction = new Vector3()
		entrance.getWorldDirection(direction)
		
		direction.y -= 0.19 * Math.PI // pure trial and error
		
		const object = new Object3D()
		object.position.copy(position)
		object.rotation.setFromVector3(direction)

		object.translateX(- OPERATOR_WIDTH * 25) // pure trial and error
		object.translateZ(60)
		object.translateY(OPERATOR_WIDTH / 2)
		

		return object
	}, [nodes])

	const stationContainers = useMemo(() => [stationContainer1, stationContainer2, stationContainer3], [stationContainer1, stationContainer2, stationContainer3]) 
	
	// cleanup
	useEffect(() => {
		return () => {
			disposeScene(scene)
			stationContainers.map(container => disposeScene(container as Group))
		}
	}, [scene, stationContainers])

	const groups: TwinEntity[][] = [[],[],[]]

	// split into hand-chosen groups
	entity.children?.forEach((child) => {
		
		const childGroupIdx = GROUP_LOOKUP.get(child.name)
		if (childGroupIdx !== undefined) {
			groups[childGroupIdx].push(child)
		}
	}
	)

	return 	<group >
			<primitive object={scene}/>
			{
			
			groups.map((group, groupIdx) => {

				const operatorNumbers = group.map(child => child.children?.length ?? 1)
				return <primitive object={stationContainers[groupIdx]} > 		
							{
								group.map(((child, idx) => {
									const operatorNumber = child.children?.length ?? 1
									const boxWidth = operatorNumber * OPERATOR_WIDTH * (1 + GAP_RATIO)
									const allPreviousOperatorNumbers = idx === 0 ? [0] : operatorNumbers.slice(0, idx)
									const sumOfAllPreviousOperator = allPreviousOperatorNumbers.reduce((partialSum, a) => partialSum + a, 0);
									const allPreviousWidth = sumOfAllPreviousOperator * OPERATOR_WIDTH * (1 + GAP_RATIO)
									return <Station
												key={idx}
												idx={idx}
												entity={child}
												boxWidth={boxWidth}
												allPreviousWidth={allPreviousWidth}
												boxDepth={STATION_DEPTH}
												lineage={[...lineage, entity]}
												mapDiv={mapDiv}
												gapRatio={GAP_RATIO}
												operatorWidth={OPERATOR_WIDTH}
											/>
									}))
							}
						</primitive>
				})
			}
		</group>
	
};

