import { useGLTF } from "@react-three/drei";
import { BoxGeometry, BufferGeometry, ColorRepresentation, MeshStandardMaterial, PlaneGeometry } from "three";
import { useEffect, useMemo } from "react";

import { useSecureModelURL } from "../../utils/modelFilePaths";
import { isMesh } from "../../helpers/threeTypeGuards";
import { degreesToRadians, getCentre } from "../../utils/geometry";
import { AreaFromEquipment } from "./AreaFromEquipment";
import { Entity } from "../../../../@types/Entity";
import { TwinEntity } from "@repo/backend-types";
import { ASSET_COLOR } from "../../theme";
import { convertCoordLocalPoint } from "../../utils/coorUtils";

type TempEquipmentModelsProps = {
	entity: Entity
	mapDiv: HTMLDivElement;
	lineage: TwinEntity[]
}

type EquipmentModelsProps = TempEquipmentModelsProps &  {
	loadingList:Map<string, boolean>;
	modelFileName: string
}

/**
	* EquipmentModels Component
    * 
	* The EquipmentModels component takes a list of nodeReferences and a single model file and creates multiple
	* models, one at each position + rotation of the nodeReferences
*/

const EQUIPTMENT_METALNESS = 0.2
const EQUIPTMENT_ROUGHNESS = 0.8

const modelMaterial = new MeshStandardMaterial({ 
	metalness: EQUIPTMENT_METALNESS,
	roughness: EQUIPTMENT_ROUGHNESS,
	color: ASSET_COLOR})				
				

const Asset = ({ entity, mapDiv, loadingList, modelFileName, lineage }: EquipmentModelsProps) => {

	
	const modelURL = useSecureModelURL(modelFileName)

	const { nodes } = useGLTF(modelURL);

	const { modelGeometry, outlineGeometry, hitBox, color } = useMemo(() => {

		let modelGeometry: BufferGeometry | undefined
		let outlineGeometry: PlaneGeometry | undefined
		let hitBox: BoxGeometry | undefined
		let color = 0xffffff

		// surely these could override the values above multiple times?
		Object.values(nodes).forEach((nv) => {
	

			if (isMesh(nv)) {
				if (!nv.geometry.boundingBox) {
					nv.geometry.computeBoundingBox();
				}

				modelGeometry = nv.geometry;
				const boundingBox = nv.geometry.boundingBox!; // this is safe because we just computed it

				outlineGeometry = new PlaneGeometry(
					- (boundingBox.min.x - boundingBox.max.x) + 0.2,
					- (boundingBox.min.z - boundingBox.max.z) + 0.2
				)

				outlineGeometry.rotateX(degreesToRadians(-90))
				outlineGeometry.translate(0,0.01,0)
				outlineGeometry.computeBoundingBox()
				
				hitBox = new BoxGeometry(1,1,1)
				hitBox.translate(0,0.5,0)
				hitBox.scale(
					(- (boundingBox.min.x - boundingBox.max.x)) + 0.02,
					(- (boundingBox.min.y - boundingBox.max.y)) + 0.02,
					(- (boundingBox.min.z - boundingBox.max.z)) + 0.02
				)
				hitBox.computeBoundingBox()
				
			} 
		}
	)
		return {modelGeometry, outlineGeometry, hitBox, color}
	}
	,[nodes]) 

	useEffect(() => {
		const alreadyLoaded = loadingList.get(modelFileName)
		if (modelGeometry && !alreadyLoaded) {
			loadingList.set(modelFileName, true)
			
		}
	}, [modelGeometry, loadingList, modelFileName])

	if (!(modelGeometry && outlineGeometry && hitBox && color)) {
		throw new Error(`asset with id ${entity.id} does not have any meshes. 
			             We don't know how to display it`)
	}

	return (<Model
				entity={entity}
				color={color}
				modelGeometry={modelGeometry}
				areaGeometry={hitBox}
				mapDiv={mapDiv}
				lineage={lineage}
			/>
					
			);
};

/**
	* TempEquipmentModels Component
    * 
	* A bit like the models above but it creates a placeholder box. Used when the proper model fetch request returns a 404
*/

const TempAsset = ({ entity, mapDiv, lineage }: TempEquipmentModelsProps) => {

	const {placeHolderGeometry, hitBox } = useMemo(()=>{

		const y = 1
		const x = y / 2
		const z = y * 1.5
		const c = 0.05

		const placeHolderGeometry = new BoxGeometry( x, y, z )
		placeHolderGeometry.translate( 0, y / 2, 0)
		placeHolderGeometry.computeBoundingBox()

		const hitBox = new BoxGeometry( x + c, y + c, z + c )
		hitBox.translate( 0, (y + c) / 2, 0 )
		hitBox.computeBoundingBox()
		

		return { placeHolderGeometry, hitBox }

	},[])

	return (<Model
				color={ASSET_COLOR}
				modelGeometry={placeHolderGeometry}
				areaGeometry={hitBox}
				mapDiv={mapDiv}
				entity={entity}
				lineage={lineage}
			/>
			);
};

interface ModelProps {
	modelGeometry: BufferGeometry;
	areaGeometry: BufferGeometry;
	color: ColorRepresentation;
	mapDiv: HTMLDivElement;
	entity: Entity;
	lineage: TwinEntity[]
}

const Model = ({ entity, mapDiv, modelGeometry, areaGeometry, lineage }: ModelProps) => {
	
	if (!entity.boundaries?.polygons) {
		throw new Error('model is missing boundaries. We dont know how to locate it without them')
	}

	let modelCentre = getCentre(entity.boundaries.polygons)

	const position = convertCoordLocalPoint(modelCentre)

	return (

		<group
			rotation={[
				0,
				degreesToRadians(-(entity.rotationY ?? 0)),
				0,
			]}
			position={position}
		>
			<mesh
			geometry={modelGeometry}
			castShadow
			receiveShadow
			material={modelMaterial}
			/>
			{
			<AreaFromEquipment 
				geometry={areaGeometry} 
				entity={entity} 
				mapDiv={mapDiv}
				lineage={lineage}
			 />
			}
		</group>
	);
}

export { Asset, TempAsset };