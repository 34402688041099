/* 
* SettingsContext
* Used to store any Site specific settings
* 
*/
import React, { createContext, useCallback, useContext, useMemo, useState } from "react"
import { Settings } from "../../@types/Settings/Settings"

import { Metric } from "@repo/backend-types"

interface SettingsContextValue {
    settings: Settings | null
    metrics: Metric[]
    setSettings: (settings: Settings | null) => void
    setMetrics: (metrics: Metric[]) => void
}

const initialState: SettingsContextValue = {
    settings: null,
    metrics: [],
    setSettings: () => { },
    setMetrics: () => { },
}

export const SettingsContext = createContext<SettingsContextValue>(initialState)

export const useSettingsContext = (): SettingsContextValue => {
    return useContext(SettingsContext)
};

interface ContextProviderProps {
    children: React.ReactNode;
}

export const SettingsContextProvider: React.FC<ContextProviderProps> = (props) => {

    const [prefState, setPrefState] = useState<SettingsContextValue>(initialState);

    const setSettings = useCallback((settings: Settings | null) => {
        setPrefState((prevPrefState) => ({ ...prevPrefState, settings }));
    }, [])

    const setMetrics = useCallback((metrics: Metric[]) => {
        setPrefState((prevPrefState) => ({ ...prevPrefState, metrics }));
    }, [])

    // Memoise the context value to prevent unnecessary re-renders
    const contextValue = useMemo(() => {
        return {
            ...prefState,
            setSettings,
            setMetrics,
        }
    }, [prefState,
        setSettings,
        setMetrics,
    ]);

    return (
        <SettingsContext.Provider
            value={contextValue}
        >
            {props.children}
        </SettingsContext.Provider>
    )
}
