/*
 * mapMetric
 * metricId takes in a Metric ID or Name value and returns a Metric
 * 
*/
import { DataMode } from "../../../@types/DataMode";
import { Metric } from "@repo/backend-types";
import { findObjectByPropertyValue } from "../findObjectByPropertyValue";

export const mapMetric = (dataMode: DataMode, metrics: Metric[], metricId: string): Metric => {
    return findObjectByPropertyValue(metrics, dataMode === DataMode.LIVE ? "id" : "name", metricId)
}