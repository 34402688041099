import React, { useEffect, useState } from "react"

import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

// Data
import { login } from "../../common/api/live/login";

// Types
import { camelCaseRawLoginData, LoginResult } from '@repo/backend-utils';
import { PageMode } from "../../@types/PageMode";

// Context
import { useFilterContext } from "../../common/contexts/FilterContext";
import { useSettingsContext } from "../../common/contexts/SettingsContext";
import { useTwinContext } from "../../common/contexts/TwinContext";
import { useUserContext } from "../../common/contexts/UserContext";

// Components
import { Button, MessageBar } from "@fluentui/react-components";
import TLInput from "../elements/TLInput";

export type FormInputs = {
    email: string,
    password: string,
};

const formSchema = {
    email: yup.string().email().required('Please enter your e-mail address'),
    password: yup.string().required('Please enter your password'),
}

interface Props {
    className?: string
    setPageMode: (pageMode: PageMode) => void
}

const Login: React.FC<Props> = ({ className, setPageMode }) => {

    const { setAuthCreds, setUser, setUserPrefs, setTwinId } = useUserContext();
    const { setSettings, setMetrics } = useSettingsContext()
    const { setTwin } = useTwinContext()
    const { clearFilter } = useFilterContext()
    const navigate = useNavigate()
    const [serverError] = useState<string>("")

    // clear out any previous user when the component first loads
    useEffect(() => {
        setUser(null)
        setAuthCreds(null)
        setUserPrefs(null)
        setTwinId(null) 
        setSettings(null)
        setMetrics([])
        setTwin(null)
        clearFilter()
    },
    // this is fine, empty array fires on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps  
    [])
    
    const { register, handleSubmit, formState: { errors } } = useForm<FormInputs>({
        resolver: yupResolver(yup.object(formSchema)),
    });



    const onSubmit: SubmitHandler<FormInputs> = async (formData) => {

            const genericAuthErrorMessage = "Unable to authenicate you. Please check your password."
            const loginResult: LoginResult = await login(formData.email, formData.password)

            if (loginResult) {
                if (loginResult.success) {

                    const parsedData = camelCaseRawLoginData(loginResult.data)

                    // Store Tokens in UserContext so that they can be used throughout the app
                    setAuthCreds({
                        authenticated: true, 
                        email: parsedData.userName, 
                        organisation: parsedData.organisation,
                        aiUrl: parsedData.aiUrl,
                        environment: parsedData.environment,
                        graphqlUrl: parsedData.graphqlUrl,
                        tokens:  {
                            aiKey: parsedData.aiKey,
                            graphqlToken: parsedData.graphqlToken,
                            mapboxToken: parsedData.mapboxToken,
                            modelUrlKey: parsedData.modelUrlKey
                        }
                    })

                    navigate('/select-twin')

                } else {
                    throw new Error(genericAuthErrorMessage);
                }
            }

    }

    return (
        <div className={className}>
            <div>
                <div
                    style={{
                        borderRadius: "8px",
                        padding: "4rem 2rem",
                        backgroundColor: "rgb(255,255,255,0.4)",
                        borderWidth: "2px",
                        borderStyle: "solid"
                    }}>

                    <h1 style={{ fontSize: '48px', lineHeight: '100%' }}>Log in</h1>
                    <p style={{ marginTop: '2rem', color: '#707070' }} className="max-w-sm">Welcome to Twinlabs, an all-in-one platform for all your data visualization needs. Helping you create easy to understand, real-time views across your events or venue.</p>
                    <div className="mt-16">
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                            <div>
                                <div className="mt-2">
                                    <TLInput
                                        className="mb-4"
                                        id="txtEmail"
                                        label="E-mail"
                                        props={{ type: "email", ...register("email") }}
                                        errorMessage={errors.email?.message}
                                    />
                                </div>
                            </div>

                            <div className="space-y-1">
                                <div className="mt-2">
                                    <TLInput
                                        className="mb-4"
                                        id="txtPassword"
                                        label="Password"
                                        props={{ type: "password", ...register("password") }}
                                        errorMessage={errors.password?.message}
                                    />
                                </div>
                            </div>

                            <div className="flex">
                                <div>
                                    <p>Remember me for 30 days</p>
                                </div>
                                <button onClick={() => setPageMode(PageMode.RESET_PASSWORD)} className="ml-auto">Forgot Password?</button>
                            </div>
                            <div className="mt-16">
                                <Button style={{
                                    width: "100%",
                                    backgroundColor: '#00BBCC',
                                    color: '#FFFFFF',
                                    fontSize: '18px',
                                    height: '40px',
                                }} type="submit">Log in</Button>
                            </div>
                            {serverError ? <MessageBar id={`server-error`} intent="error">{serverError}</MessageBar> : null}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login