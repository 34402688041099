import {
	useEffect,
	useLayoutEffect,
	useMemo,
	useState, 
} from "react";
import { useGLTF } from "@react-three/drei";
import { useSecureModelURL } from "../../utils/modelFilePaths";
import { Box3, BoxGeometry, Euler, Mesh, MeshStandardMaterial, Vector3 } from "three";
import { isMesh } from "../../helpers/threeTypeGuards";
import { degreesToRadians, getCentre } from "../../utils/geometry";
import { Point, TwinEntity } from "@repo/backend-types";
import { BuiltOutEntity } from "../../../../@types/3D/Area";
import { AreaFromStructure } from "./AreaFromStructure";
import { Wind } from "../../behaviours/Wind";
import { coordsToVector3 } from "react-three-map";
import { ASSET_COLOR } from "../../theme";


const structureMaterial = new MeshStandardMaterial({
                color: ASSET_COLOR,
                opacity:0.9,
                roughness: 0.8,
                metalness: 0.2,
  })

interface Props {
	entity: TwinEntity,
	modelFile: string,
	childEntities?: TwinEntity[]
	mapDiv: HTMLDivElement;
	loadingList: Map<string, boolean>;
	depth: number // the depth we're at in the twin heirachy
	lineage: TwinEntity[] // the acenstry of this entity, oldest first
	mapOrigin: Point
}

const Structure = ({ entity, childEntities, modelFile, mapDiv, loadingList, depth, lineage, mapOrigin }: Props) => {
	
	const modelURL =  useSecureModelURL(modelFile)

	const [hasModel, setHasModel] = useState(false)
    
    useLayoutEffect(() => {

        const checkModelURL = async () => {

            try {

                const response = await fetch(modelURL);

                if(response.ok){
                    setHasModel(response.ok)
                } else {
					loadingList.delete(modelFile)
				}

            } catch (error) {

            console.error('Error fetching data: ', error, 'hasModel?: ' + hasModel);
            }
        }

        checkModelURL()

    },[hasModel, modelURL, loadingList, modelFile])

	const { nodes } = useGLTF(modelURL)

	const structureBoundingBox = Object.entries(nodes).map(entry => {
		const object = entry[1]
		if (isMesh(object)) {
			object.geometry.computeBoundingBox()
			const boundingbox = object.geometry.boundingBox!
			return boundingbox
		} else {
			return null
		}}
        ).filter((box): box is Box3 => !!box
		).reduce((box1, box2) => {
			const minx = Math.min(box1.min.x, box2.min.x)
			const miny = Math.min(box1.min.y, box2.min.y)
			const minz = Math.min(box1.min.z, box2.min.z)
			const maxx = Math.min(box1.max.x, box2.max.x)
			const maxy = Math.min(box1.max.y, box2.max.y)
			const maxz = Math.min(box1.max.z, box2.max.z)

			return new Box3(new Vector3(minx, miny, minz), new Vector3(maxx, maxy, maxz))
		})

		const structureGeometry = new BoxGeometry(1,1,1)
		
		structureGeometry.translate(0,.5,0) // because box geometry is origin (0,0,0) initially
		structureGeometry.scale(
			(structureBoundingBox.max.x - structureBoundingBox.min.x) * 10, // these 10s are random
			(structureBoundingBox.max.y - structureBoundingBox.min.y) * 10,
			(structureBoundingBox.max.z - structureBoundingBox.min.z) * 10
		)

	const modelNodes = useMemo(()=>{

		const modelEntities: {mesh: Mesh, builtOutEntity?: BuiltOutEntity, structureNodeEntity?: TwinEntity}[] = [];

		// for Movico we don't need to recurse into the structure nodes but we
		// will need to in order to support arbitrary gltfs
		Object.values(nodes).forEach((nv) => {

			if (isMesh(nv)) {

				if (!nv.geometry.boundingBox) {
					nv.geometry.computeBoundingBox();
				}

				const boundingBox = nv.geometry.boundingBox! // this is safe because we just computed it

				const hitBoxGeometry = new BoxGeometry(1,1,1)
				hitBoxGeometry.translate(0,0,0)
				hitBoxGeometry.scale(
					(- (boundingBox.min.x - boundingBox.max.x)) + 0.1,
					(- (boundingBox.min.y - boundingBox.max.y)) + 0.01,
					(- (boundingBox.min.z - boundingBox.max.z)) + 0.1
				)

				const structureNodeEntityName = nv.name.replace(/_/g, ' ');
				
				const structureNodeEntity: TwinEntity | undefined = childEntities?.find((childEntity) => {
				return childEntity.name.toUpperCase() === structureNodeEntityName.toUpperCase()
				})


				if ( structureNodeEntity ) {

					const builtOutEntity = {
						...structureNodeEntity,
						geometry: nv.geometry,
						hitBoxGeometry,
						tracked: true
					} 
					modelEntities.push({ mesh: nv, builtOutEntity, structureNodeEntity})
				} else {
					modelEntities.push({ mesh: nv})
				}	
			}
		});


		return modelEntities

	},[nodes, childEntities])

	useEffect(() => {
		const alreadyLoaded = loadingList.get(modelFile)
		if (modelNodes && !alreadyLoaded) {
			loadingList.set(modelFile, true)
			
		}
	}, [modelNodes, loadingList, modelFile])

	let centre = { latitude: 0, longitude: 0 }

	if (entity.boundaries) {
		centre = getCentre(entity.boundaries.polygons)
	} else {
		throw new Error(`structure with id ${entity.id} is missing boundaries. 
						We need boundaries in order to know where to load it on the map` )
	}
	
	const position = new Vector3(...coordsToVector3(
		{
			longitude: centre.longitude/1000 + mapOrigin.longitude,
			latitude: centre.latitude/1000 + mapOrigin.latitude, 
		},
		{
			longitude: mapOrigin.longitude,
			latitude:  mapOrigin.latitude,
		}
	));

	
	const rotation = new Euler(
		degreesToRadians(entity.rotationX ?? 0),
		degreesToRadians(entity.rotationY ? entity.rotationY - 90 : 0), // TODO adjust this since it's relative to north now
		degreesToRadians(entity.rotationZ ?? 0),
	)


	return (

		<group 
			position={position}
			rotation={rotation}
		>
			{/* TODO: Handle Time series */}
			<Wind entity={entity} geometry={structureGeometry}  parentWorldRotation={rotation}/>
			{
				modelNodes.map((modelNode, meshIndex) => {
					
					return 	<mesh
								key={meshIndex}
								material={structureMaterial}
								castShadow
								receiveShadow
								geometry={modelNode.mesh.geometry}
								position={modelNode.mesh.position}
							>
							{
								modelNode.builtOutEntity && modelNode.structureNodeEntity &&
								<AreaFromStructure 
									depth={depth}
									entity={modelNode.structureNodeEntity}
									geometry={modelNode.builtOutEntity.geometry} 
									hitboxGeometry={modelNode.builtOutEntity.hitBoxGeometry}
									mapDiv={mapDiv} 
									lineage={[...lineage, entity]}
								/>
							}	
							</mesh>
				})
			}
		</group>
	);
};



export { Structure };
