/*
 * resolveEndpoints
 *
*/
export const resolveEndpoints = (graphqlUrl, config) => {
    let httpUrl;
    let wsUrl;
    if (config.useLocalDgraph) {
        httpUrl = 'http://localhost:8080/graphql';
        wsUrl = 'ws://localhost:8080/graphql';
    }
    else {
        httpUrl = `https://${graphqlUrl}`;
        wsUrl = `wss://${graphqlUrl}`;
    }
    return {
        httpUrl,
        wsUrl,
    };
};
