import { useFrame } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { Box3, BufferGeometry, ColorRepresentation, Euler, Group,  Mesh, Texture, Vector3 } from "three"
import { degreesToRadians } from "../utils/geometry";
import { useGLTF, useTexture } from "@react-three/drei";
import { useSecureModelURL } from "../utils/modelFilePaths";
import { WindMaterial } from "../materials/WindMaterial";
import { useDataContext } from "../../../common/contexts/DataContext";
import { getWindOrientation } from "../../../common/utils/func-metrics/getWindOrientation";
import { getWindSpeed } from "../../../common/utils/func-metrics/getWindSpeed";
import { DataMode } from "../../../@types/DataMode";
import { TwinEntity } from "@repo/backend-types";

interface Props{
    entity: TwinEntity
    geometry: BufferGeometry
    parentWorldRotation: Euler // since this might be child of another object, in that case you need to offset the wind direction
}

function getRandomNumberInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
}

function generatePositionInsideBox(box: Box3) {


    const x = getRandomNumberInRange(box.min.x, box.max.x)
    const y = getRandomNumberInRange(box.min.y, box.max.y)
    const z = getRandomNumberInRange(box.min.z, box.max.z)
    return new Vector3(x, y, z)
}

const TARGET_NUMBER_OF_PARTICLES = 50

enum SpeedLevels {
    VERY_SLOW = 2.11,
    SLOW = 4.11,
    AVERAGE = 8.43,
}

// speed should be in m/s
function getWindIndicatorLength(speed: number) {
    if (speed < SpeedLevels.VERY_SLOW) {
        return 1
    } else if (speed < SpeedLevels.SLOW) {
        return 2
    } else if (speed < SpeedLevels.AVERAGE) {
        return 3
    } else {
        return 5
    }
}

// speed should be in m/s
function getWindIndicatorColor(speed: number): ColorRepresentation {
    if (speed < SpeedLevels.VERY_SLOW) {
        return 0xBEF4F2
    } else if (speed < SpeedLevels.SLOW) {
        return 0x8FE6E8
    } else if (speed < SpeedLevels.AVERAGE) {
        return 0xF5D8DF
    } else {
        return 0xC50F1F
    }
}

class WindMesh extends Mesh {

    material: WindMaterial
    constructor(geometry: BufferGeometry, direction: number,  texture: Texture, color?: ColorRepresentation) {
        
        const material = new WindMaterial(direction, color ?? 'white', texture)
        super(geometry, material)

        this.material = material
    }
}

const MODEL_SCALE_MULTIPLIER = 3
const VARIATION_SCALE = 0.01
const PLACEHOLDER_WIND_ORIENTATION = 0
const PLACEHOLDER_WIND_SPEED = 10

const Wind = ({ entity, geometry, parentWorldRotation }: Props) => {

    const boxRef = useRef<Group>(null)
    const particleMeshes = useRef<WindMesh[]>([])
    const dropTexture = useTexture('/assets/images/textures/wind-indicator.png')

    const dropModelURL = useSecureModelURL('drop.glb' )
    const { nodes } = useGLTF(dropModelURL)
    
    const { data } = useDataContext()

    // canonical form https://www.notion.so/Telemetry-Metric-Weather-6cf4debe18d54b8799e5c4e3252d1b54
    // degrees, direction it is travelling from
    const orientation = data.processed ?  parseFloat(getWindOrientation(
                                DataMode.LIVE, 
                                data.processed.windOrientation, 
                                entity.id)) 
                                : PLACEHOLDER_WIND_ORIENTATION

    // in km/h from canonical form
    const speed = data.processed ? parseFloat(getWindSpeed(
                                DataMode.LIVE, 
                                data.processed.windSpeed, 
                                entity.id))
                                : PLACEHOLDER_WIND_SPEED



    // convert from canonical form units to our preffered
    const speedMs = speed * 0.277778 // converted to metres/second
    let orientationRadians = -degreesToRadians(orientation)
    
    // adding the roations feels wrong but it looks right. I think the reason it works is because
    // the structures y axis points in the opposite direction to the world (not sure why, but axeshelpers show it) so it's
    // rotation is opposite to the world
    orientationRadians -= parentWorldRotation.y  
    orientationRadians += Math.PI  

    // N.B. north is -z
    const xMovement = -Math.sin(orientationRadians) * speedMs
    const zMovement = -Math.cos(orientationRadians) * speedMs 


    const updateIndicatorDirection = (indicator: WindMesh) => {  
        indicator.quaternion.setFromAxisAngle(new Vector3(0,1,0).normalize(), orientationRadians)
    }

    const updateIndicatorColor = (indicator: WindMesh) => {
     
        indicator.material.updateColor(getWindIndicatorColor(speedMs))
    }

    const updateIndicatorLength = (indicator: Mesh) => {

        indicator.scale.copy(new Vector3(1,1, getWindIndicatorLength(speedMs)).multiplyScalar(MODEL_SCALE_MULTIPLIER))
    }

   
    const [particlesInitialized, setParticlesInitialized] = useState(false)
    geometry.computeBoundingBox()
    const box = geometry.boundingBox!

    useEffect(() => {
        particleMeshes.current.forEach((particle) => {
        
        updateIndicatorDirection(particle)
        updateIndicatorColor(particle)
        updateIndicatorLength(particle)

    }, [speedMs])
        
    })

    useEffect(() => {
        
        const createMeshParticleInsideBox = (box: Box3) => {
            const position = generatePositionInsideBox(box)
           
            const modelMesh = nodes["average_drop"] as Mesh
            const geometry = modelMesh.geometry

            const mesh = new WindMesh(geometry, orientationRadians, dropTexture)
            mesh.position.copy(position)
            return mesh
        }
        
        if (!particlesInitialized && boxRef.current) {
            for (let i = 0; i < TARGET_NUMBER_OF_PARTICLES; i++) {
                const particleMesh = createMeshParticleInsideBox(box)
                particleMeshes.current.push(particleMesh)
                boxRef.current.add(particleMesh)
            }

            setParticlesInitialized(true)
        }

    },[
        particlesInitialized, 
        dropTexture,
        orientationRadians,
        nodes,
        box, 
        ])

    function updateParticles(dt: number) {
   
        // move them in the wind direction
        particleMeshes.current.forEach((particle: WindMesh) => {

            particle.material.addTime(dt)
            const randomVariation = new Vector3(
                getRandomNumberInRange(-VARIATION_SCALE, VARIATION_SCALE),
                getRandomNumberInRange(-VARIATION_SCALE, VARIATION_SCALE),
                getRandomNumberInRange(-VARIATION_SCALE, VARIATION_SCALE)
            )
    
            // any which are outside the bounding box, 
            if (particle.position.x <= box.min.x ||
                particle.position.z <= box.min.z ||
                particle.position.x >= box.max.x ||
                particle.position.z >= box.max.z 
            ) {// generate a new position for them inside the box
                const position = generatePositionInsideBox(box)
                particle.position.copy(position)
              } else {
                particle.position.x += (xMovement * dt)
                particle.position.z += (zMovement * dt)
                particle.position.add(randomVariation)
              }
        })

    }

    useFrame((_, dt)=> {

        updateParticles(dt) 
        
    })

    return <group ref={boxRef } />
        
   
}


export { Wind }